import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/shahid/Desktop/Projects/Personal/madrasatulilm.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import { Box, Breadcrumb, Divider, Flex, SEO, Text, Video } from '../../../views/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO pageTitle="4D Block" mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
  <Text variant="h2" textAlign="center" mdxType="Text">
    4D Block
  </Text>
  <Divider mdxType="Divider" />
  <Breadcrumb links={[{
        url: '/',
        name: 'নীড়পাতা'
      }, {
        url: '/finance/',
        name: 'আর্থিক ব্যাপার'
      }]} mdxType="Breadcrumb" />
  <Divider mdxType="Divider" />
      <Flex mx={[0, -2]} flexWrap="wrap" mdxType="Flex">
  <Box width={[1, 1 / 2]} p={3} mdxType="Box">
    <img src="/assets/images/4d-block-1.png" />
  </Box>
  <Box width={[1, 1 / 2]} p={3} mdxType="Box">
    <img src="/assets/images/4d-block-2.jpg" />
  </Box>
      </Flex>
      <p>{`আনিস সাহেব প্রতিদিন বাচ্চাকে ‘কায়দা’ পড়ান। অনেক কষ্টে একসময় শিখিয়ে ফেললেন ২৯ টি আরবী বর্ণ। একদিন সূরা ‘ফীল’ পড়ছিলেন আনিস সাহেব। বাচ্চাকে فيل (ফীল- হাতি) শব্দটি দেখিয়ে বললেন, “দেখেছ বাবা, ف, ي আর ل মিলে হয়েছে فيل।”`}</p>
      <p>{`বাচ্চা জবাব দেয়, “আব্বু, আমি তো فيل শব্দের মাঝে ي আর ف খোঁজে পাচ্ছি না!”`}</p>
      <p>{`সমস্যাটা কোথায় বোঝতে পারলেন আনিস সাহেব। আরবী বর্ণের স্বতন্ত্র রূপ ছাড়াও শব্দের শুরু, মাঝে ও শেষের জন্য রয়েছে আরো ৩টি রূপ। অর্থাৎ, এক হরফের মোট ৪টি ডাইমেনশন (4D)।`}</p>
      <p>{`এবার বাচ্চাকে আবার ২৯ টি আরবী বর্ণ শিখালেন 4D হরফ দিয়ে।`}</p>
      <p>{`“দেখো বাবা, এটা হচ্ছে ي। ব্লকটা ঘুরিয়ে ঘুরিয়ে দেখ, নিচে দুই নুকতাওয়ালা ৪টা হরফ-ই ي।”`}</p>
      <p>{`“হ্যা আব্বু, ف কোনটা সেটাও আমি শিখে গিয়েছি। একটা গোল্লার উপর এক নুকতাওয়ালা সবই ف।” হাতে একটি 4D ف দেখিয়ে আব্বুকে বলে।`}</p>
      <p>{`“ঠিক, এবার তাহলে ف, ي আর ل তিনটি হরফ এর লেজে-লেজে কানেকশন দিয়ে فيل বানাও তো।”`}</p>
      <Video videoSrcURL="https://www.youtube.com/embed/p5BkpyPYprA" videoTitle="আরবী 4D হরফ, Arabic 4 Dimensional Alphabets" frameWidth="720px" frameHeight="504px" width="100%" mb={4} mdxType="Video" />
      <p><strong parentName="p">{`মূল্য`}</strong>{`: ৪৩০ টাকা (প্রাইস ফ্লেক্সিবল, কেউ কম দিতে চাইলে মাদ্রাসা কম প্রফিট করবে।)`}</p>
      <p><strong parentName="p">{`হোম ডেলিভারি`}</strong>{`: ১০০ টাকা বা তার কম (দূরত্ব বুঝে)।`}</p>
      <p>{`টাকা পাঠানোর জন্য:`}</p>
      <ul>
        <li parentName="ul">{`সরাসরি ডেলিভারি ম্যান এর কাছে,`}</li>
        <li parentName="ul">{`বা নগদ একাউন্টে 01911494547`}</li>
        <li parentName="ul">{`বা ব্যাংক একাউন্টে (20502240201192211, IBBL Agargaon Branch)`}</li>
      </ul>
      <p><strong parentName="p">{`যোগাযোগ`}</strong>{`: WhatsApp & Call: 01914-723676`}<br />{`
`}<strong parentName="p">{`Mail`}</strong>{`: `}<a parentName="p" {...{
          "href": "mailto:madrasatulilm.contact@gmail.com"
        }}>{`madrasatulilm.contact@gmail.com`}</a></p>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      